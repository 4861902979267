import { alpha } from "@mui/material";

export const PRIMARY_COLOR = "#1c69d1";
export const PRIMARY_COLOR_DARK = "#031426";
export const ERROR_COLOR = "#f00";
export const SECONDARY_COLORS = {
  /**
   * TODO - identify colors
   */
  light: "#0086e6",
  dark: "#00d0ff",
};

export const APP_BAR_COLORS = {
  backgroundColors: {
    light: "#001529",
    dark: "#fff",
  },
  colors: {
    light: "#fff",
    dark: "#000",
  },
};

export const BLUE_03 = "#001529";
export const BLUE_GREEN_B8 = "#a9b7b8";

export const BACKGROUND_DARK_MODE = "#0a1929";
export const GRAY_00 = "#000";
export const GRAY_4F = "#4f4f4f";
export const GRAY_82 = "#828282";
export const GRAY_C0 = "#c0c0c0";
export const GRAY_E0 = "#e0e0e0";
export const GRAY_E5 = "#e5e2e5";
export const GRAY_F0 = "#f0f0f0";
export const GRAY_F5 = "#f5f5f5";

export const TOTAL_PAY_COLOR = "#1c69d1";
export const PRIORITY_ACCESS_COLOR = "#6A67CE";

export const ALERT_COLORS = {
  warning: {
    backgroundColor: "#FFF4E5",
    color: "#663C00",
  },
  info: {
    backgroundColor: "#E5F6FD",
    color: "#014361",
  },
  success: {
    backgroundColor: alpha("#81C784", 0.25),
    color: "#212529",
  },
  insight: {
    backgroundColor: alpha(PRIORITY_ACCESS_COLOR, 0.25),
    color: "#212529",
  },
};

export const TYPOGRAPHY_COLORS = {
  body2: {
    light: GRAY_4F,
    dark: GRAY_00,
  },
};

export const PRIMARY_HIGHLIGHT_COLORS = {
  color: "#FDC67D",
  backgroundColor: "#FDC67D40",
};

export const SECONDARY_HIGHLIGHT_COLORS = {
  color: "#1C69D1",
  backgroundColor: "#E6F7FF",
};
