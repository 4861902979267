import { Stack, type StackProps } from "@mui/material";
import { forwardRef } from "react";

import { mergeSxProps } from "../../mui";

export type UlProps = Omit<StackProps<"ul">, "component">;

export const Ul = forwardRef<HTMLOListElement, UlProps>((props, ref) => {
  const { sx } = props;
  return (
    <Stack
      {...props}
      ref={ref}
      sx={mergeSxProps({ paddingLeft: 2 }, sx)}
      spacing={1}
      component="ul"
    />
  );
});

Ul.displayName = "Ul";
